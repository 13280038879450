<template>
  <div class="app_info">
    <div class="wrapper">
      <img src="~assets/img/products/appInfo.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInfo'
}
</script>

<style scoped>
.app_info {
  text-align: center;
  width: 100%;
  min-width: 1220px;
}

.wrapper {
  width: 960px;
  margin: 60px auto 80px;
}

.wrapper img {
  width: 100%;
}
</style>
